import CryptoJS from "crypto-js";

// 过滤请求参数 包括 '', null, undefined
const filterEmptyParams = params => {
  const result = {};

  if (typeof params === "object") {
    Object.keys(params).forEach(key => {
      const value = params[key];
      if (value === 0 || value) {
        result[key] = value;
      }
    });
  }

  return result;
};

// 计算请求签名
const getSign = (method, url, params) => {
  if (!params || typeof params !== "object") return;

  const tempArr = Object.keys(params).sort();

  let str = String(method).toUpperCase() + url;

  tempArr.forEach((key, index) => {
    if (index) {
      str += `&${key}=${params[key]}`;
    } else {
      str += `${key}=${params[key]}`;
    }
  });

  // 添加固定字符串 与后台约定
  // str += "&1acbc652e08745fab4bbed2f5afd1618";
  str += "&1337a92322fd466cadef621c0eedccf2";

  return CryptoJS.enc.Hex.stringify(CryptoJS.MD5(str));
};

export { filterEmptyParams, getSign };
